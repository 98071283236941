import React, { useContext } from "react";

import { DataContext } from "../../../../../providers/ElectricityCheckoutDataProvider";
import OrderBottomBar from "../../../../shared/OrderBottomBar";

const BottomBar = () => {
    const dataContext = useContext(DataContext);
    const { product } = dataContext.data;

    if (!product) {
        return null;
    }
    return (
        <OrderBottomBar
            leftTextBefore="Monatlich:"
            price={`${product.gesamtpreisBruttoProMonat} €`}
            totalPrice={`${product.gesamtpreisOhneBoniBrutto} €`}
            rightText="Gesamt:"
        />
    );
};

export default BottomBar;
