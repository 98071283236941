import React from "react";

import { graphql } from "gatsby";

import CheckoutPage from "../../../components/pages/Electricity/CheckoutPage";
import Robots from "../../../components/shared/Meta/Robots";
import Page, {
    PRODUCT_STROM,
    RESPONSIBLE_COMPANY_HOYER_STROM_GAS,
} from "../../../components/shared/Page";
import ElectricityDataProvider from "../../../providers/ElectricityDataProvider";
import Error404Page from "../../404";

const StromBestellungPage = ({ params: { checkoutStep } }) =>
    checkoutStep == null ? (
        <Error404Page />
    ) : (
        <Page
            responsibleCompany={RESPONSIBLE_COMPANY_HOYER_STROM_GAS}
            product={PRODUCT_STROM}
        >
            <Robots index={false} />

            <ElectricityDataProvider>
                <CheckoutPage {...{ checkoutStep }} />
            </ElectricityDataProvider>
        </Page>
    );
export default StromBestellungPage;

export const query = graphql`
    query {
        page: otherPagesYaml(slug: { eq: "strom" }) {
            ...Page
        }
    }
`;
