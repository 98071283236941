import React, { useContext } from "react";

import { css } from "@emotion/react";

import { DataContext } from "../../../../../providers/ElectricityCheckoutDataProvider";
import { navigate } from "../../../../shared/LinkComponent";
import NewButton from "../../../../shared/NewForms/Button";
import Section from "../../../../shared/Section";
import { textAlign } from "../../../../shared/TextAlign";
import * as styles from "./style.module.scss";

const StepButtons = () => {
    const dataContext = useContext(DataContext);
    const { editMode, nextButtonDisabled, currentStepIndex } = dataContext.data;
    const {
        nextStep,
        previousStep,
        setEditMode,
        setStepButtonAction,
        previousStepVisible,
    } = dataContext.actions;

    const next = () => {
        setStepButtonAction("next");
        if (editMode) {
            setEditMode(false);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            navigate("/strom/bestellung/uebersicht");
        } else {
            nextStep();
        }
    };

    const previous = () => {
        setStepButtonAction("previous");
        if (editMode) {
            setEditMode(false);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            navigate("/strom/bestellung/uebersicht");
        } else {
            previousStep();
        }
    };

    return (
        <Section withMargin>
            {currentStepIndex < 3 ? (
                <div className="columns is-mobile ">
                    <div className="column">
                        {previousStepVisible() && (
                            <NewButton
                                id="nextStepButton"
                                color="grey"
                                onClick={previous}
                            >
                                Zurück
                            </NewButton>
                        )}
                    </div>
                    <div
                        className="column"
                        css={css`
                            ${textAlign("right")}
                        `}
                    >
                        {!nextButtonDisabled && currentStepIndex !== 4 && (
                            <NewButton onClick={next}>
                                {editMode ? "Speichern" : "Weiter"}
                            </NewButton>
                        )}
                    </div>
                </div>
            ) : (
                <div className={styles.finalWrapper}>
                    {currentStepIndex !== 4 && (
                        <NewButton color="green" onClick={next}>
                            Kostenpflichtig bestellen
                        </NewButton>
                    )}
                </div>
            )}
        </Section>
    );
};

export default StepButtons;
